"use client"

import { TSocketMessage } from "../types"
import { useEffect, useRef, useState } from "react"
import io, { type Socket } from "socket.io-client"
import { IS_DEVELOPMENT } from "../constants"

export const FINISH_STATUS = [
  "UPLOAD_VALIDATION_FAILED",
  "UPLOAD_VIRUS_DETECTED",
  "UPLOAD_SUCCESS",
]

const SCANNING_STATUS = {
  INITIATE_UPLOAD: 92,
  UPLOAD_ON_METADATA_VALIDATION: 95,
  UPLOAD_ON_MALWARE_SCANNING: 98,
}

type TTimeout = {
  /**
   * timeout duration in ms
   */
  duration: number
  message: string
}

type TCreateSocket = {
  fileToken: string
  file?: File
  baseURl: string
}

type TUseUploadProps = {
  onError?: (err: string) => void
  onSuccessUpload?: (signedUrl: string, file?: File) => void
  errorMessage?: {
    validationFailed?: string
    virusDetected?: string
  }
  timeout?: TTimeout
}
const useGetUploadStatusV2 = ({
  onError,
  errorMessage,
  onSuccessUpload,
}: TUseUploadProps) => {
  const websocket = useRef<Socket>()
  const [percentage, setPercentage] = useState(0)
  const [standbyCount, setStandbyCount] = useState(0)
  const [file, setFile] = useState<File>()

  const handleError = (err: string) => {
    if (onError) {
      onError(
        err || "Telah terjadi kesalahan, mohon ulangi dalam beberapa saat lagi"
      )
    }
    setPercentage(0)
  }

  const closeSocket = () => {
    if (websocket.current) {
      ;(
        websocket.current as unknown as { closeSocket: () => void }
      )?.closeSocket()
    }
  }

  const createSocket = ({ baseURl, fileToken, file }: TCreateSocket) => {
    websocket.current = io(baseURl, {
      path: "/api/getUploadStatus",
      transports: IS_DEVELOPMENT ? undefined : ["websocket"],
    })

    websocket.current.on("connect", () => {
      websocket.current?.emit("clientAction", "CREATE_SOCKET")
    })

    websocket.current.on("closeSocket", () => {
      websocket.current?.emit("clientAction", "CLOSE_SOCKET")
      websocket.current = undefined
    })

    websocket.current.on("errorSocket", (errorMsg: string) => {
      websocket.current?.emit("clientAction", "CLOSE_SOCKET")
      websocket.current = undefined
    })

    websocket.current.on("backendMessage", (data: string) => {
      const dataResponse = JSON.parse(data) as TSocketMessage
      const status = dataResponse?.payload?.data?.getUploadStatus || ""

      if (dataResponse.type === "connection_ack") {
        websocket.current?.emit("clientRequest", fileToken)
        return
      }

      /**
       * "ka" type is response backend to keep the connection alive during idle periods
       * If we receive 5 consecutive "ka" messages without any getUploadStatus updates, it indicates prolonged inactivity and will close automatically
       */
      if ((dataResponse.type = "ka") && standbyCount < 5) {
        setStandbyCount((prev) => prev + 1)
      }

      if (!FINISH_STATUS.includes(status)) {
        setPercentage(
          (prev) =>
            SCANNING_STATUS?.[status as keyof typeof SCANNING_STATUS] || prev
        )
        return
      }
      switch (status) {
        case FINISH_STATUS[2]:
          setPercentage(0)
          onSuccessUpload && onSuccessUpload(fileToken, file)
          break
        case FINISH_STATUS[1]:
          setPercentage(0)
          handleError(errorMessage?.virusDetected ?? "")
          break
        case FINISH_STATUS[0]:
          setPercentage(0)
          handleError(errorMessage?.validationFailed ?? "")
          break
        default:
          handleError("")
          break
      }

      websocket.current?.emit("clientAction", "CLOSE_SOCKET")
      websocket.current = undefined
    })
  }

  const getUploadStatus = (props: TCreateSocket) => {
    createSocket(props)
    setStandbyCount(0)
  }

  useEffect(() => {
    window.addEventListener("beforeunload", closeSocket)
    return () => {
      window.addEventListener("beforeunload", closeSocket)
    }
  }, [])

  return {
    percentage,
    file,
    getUploadStatus,
  }
}

export default useGetUploadStatusV2
